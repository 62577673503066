import { HTTP } from '@/http';

const images = {
  namespaced: true,
  state: {
    ids: [],
    idsPending: [],
  },
  mutations: {
    FETCH_IMAGES: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },

    REMOVE_IMAGE: (state, id) => {
      const idx = state.ids.indexOf(id);
      if (idx !== -1) {
        state.ids.splice(idx, 1);
      }
    },
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.images[id]);
    },
  },
  actions: {
    fetchImages({ state, commit, rootState }, params) {
      return HTTP.get('/crud/image', { params }).then((response) => {
        commit(
          'FETCH_IMAGES',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'images',
            payload: response.data.models,
          },
          { root: true }
        );
        return response.data.models;
      });
    },
    addImage({ state, commit }, params) {
      return HTTP.post('/crud/image', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_IMAGES', [response.data._id]);
          commit(
            'FETCH_MODELS',
            {
              type: 'images',
              payload: [response.data],
            },
            { root: true }
          );
          return response.data._id;
        }
      });
    },
    removeImage({ state, commit }, params) {
      HTTP.delete('/crud/image', { data: { ...params } })
        .then((response) => {
          commit('REMOVE_IMAGE', params._id);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
  },
};

export default images;
