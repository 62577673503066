<template>
  <div class="lesson-view">
    <div class="page-lesson__col-body">
      <div class="page-lesson__pending" v-if="pending">
        <base-spinner size="50px"></base-spinner>
      </div>

      <div v-else>
        <div class="page-lesson__preview-blocks" v-if="activeTab === 'blocks'">
          <div class="page-lesson__blocks">
            <base-button theme="dashed" size="big" variant="animated" @click="createNewBlock(0)">
              <base-icon glyph="#icon-plus" width="22px" />
              Добавить блок
            </base-button>
            <div v-for="block in sortBlocks" :key="block._id">
              <editable-lesson-block
                :block="block"
                :id="`${colId}_block_${block._id}`"
                :colId="colId"
                @removeBlock="deleteBlock"
                @scroll-blocks="scrollBlocks"
                @swap="handleSwapBtns"
                @uploadFile:open="openUploadFileModal"
                @copyBlock="copyBlock"
              />

              <base-button theme="dashed" size="big" variant="animated" @click="createNewBlock(block.idx)">
                <base-icon glyph="#icon-plus" width="22px" />
                Добавить блок
              </base-button>
            </div>
          </div>
        </div>

        <div class="page-lesson__preview-new" v-if="activeTab === 'new'">
          <h2 v-if="lesson && lesson.name" class="page-lesson__title">
            {{ lesson.name }}
          </h2>
          <div class="page-lesson__subjects">
            <div class="page-lesson__subject" v-if="subject">
              {{ subject.name }}
            </div>
            <div class="page-lesson__subject-area" v-if="subjectArea">
              {{ subjectArea.name }}
            </div>
          </div>
          <lesson-block
            v-for="block in sortBlocks"
            :key="block._id"
            :block="block"
            :id="`${colId}_preview_${block._id}`"
            :colId="colId"
            @scroll-blocks="scrollBlocks"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import EditableLessonBlock from '@/components/EditableLessonBlock';
import LessonBlock from '@/components/LessonBlock';
import ModalNewBlock from '@/components/Modal/ModalNewBlock';
import ModalUploadFile from '@/components/Modal/ModalUploadFile';

export default {
  name: 'LessonView',
  components: {
    EditableLessonBlock,
    LessonBlock,
  },
  props: {
    colId: String,
    pending: Boolean,
    lesson: Object,
    blocksList: Array,
    startTab: {
      type: String,
      default: 'blocks',
    },
    subject: Object,
    subjectArea: Object,
  },
  data() {
    return {
      activeTab: '',
    };
  },
  computed: {
    ...mapGetters('images', { listImg: 'list' }),
    ...mapGetters('variants', { listVariants: 'list' }),

    sortBlocks() {
      if (this.blocksList && this.lesson && this.lesson.blocks) {
        return this.blocksList
          .filter((block) => this.lesson.blocks.includes(block._id))
          .sort((a, b) => {
            if (a.idx > b.idx) return 1;
            if (a.idx < b.idx) return -1;
            return 0;
          });
      }
      return [];
    },
  },
  methods: {
    ...mapActions('images', ['addImage']),
    ...mapActions('variants', ['addVariant', 'setQuestion', 'setAnswer']),
    ...mapActions('lessons', ['changeLesson']),
    ...mapActions('lessonBlocks', ['removeBlock', 'swapBlocks', 'idxUpdate', 'addBlock', 'changeBlock']),

    openUploadFileModal(current, handleSave) {
      this.$emit('vuedals:new', {
        name: 'upload-file',
        component: ModalUploadFile,
        dismissable: false,
        size: 'sm',
        props: {
          current,
          handleSave,
        },
      });
    },

    createNewBlock(idx) {
      this.$emit('vuedals:new', {
        name: 'new-block',
        component: ModalNewBlock,
        dismissable: false,
        size: 'sm',
        props: {
          idx,
          lessonId: this.lesson._id,
          handleConfirm: (block) => this.pushNewBlock(block, idx),
        },
      });
    },

    async pushNewBlock(block, idx) {
      this.lesson.blocks.push(block._id);
      await this.idxUpdate({
        lessonId: this.lesson._id,
        idx: idx + 1,
        _id: block._id,
      });
      this.scrollBlocks(`col-1_${block._id}`);
      this.scrollBlocks(`col-2_${block._id}`);
    },

    deleteBlock(blockId) {
      this.removeBlock({ _id: blockId });
      this.lesson.blocks = this.lesson.blocks.filter((block) => block !== blockId);
      this.changeLesson({ _id: this.lesson._id, blocks: this.lesson.blocks });
    },
    scrollBlocks(id) {
      this.$emit('scroll-blocks', id);
    },
    handleSwapBtns(id, direction) {
      const currentIdx = this.sortBlocks.findIndex((block) => block._id === id);
      if (currentIdx === -1) return null;

      let id2 = 0;
      if (direction === 'up') {
        if (currentIdx === 0) return null;
        id2 = this.sortBlocks[currentIdx - 1];
      } else {
        if (currentIdx === this.sortBlocks.length - 1) return null;
        id2 = this.sortBlocks[currentIdx + 1];
      }

      this.swapBlocks({ id1: id, id2 });
      return null;
    },

    async copyBlock(blockId) {
      const currentBlock = this.blocksList.find(({ _id }) => _id === blockId);
      const newBlock = await this.addBlock({
        idx: currentBlock.idx + 1,
        name: `${currentBlock.name} (копия)`,
        type: currentBlock.type,
        lesson: this.lesson._id,
      });
      const fields = {
        _id: newBlock._id,
        description: currentBlock.description,
        is_home_work: currentBlock.is_home_work,
        link: currentBlock.link,
        tags: currentBlock.tags,
        text: currentBlock.text,
        title: currentBlock.title,
        videoPoster: currentBlock.videoPoster,
        links: currentBlock.links,
      };

      if (currentBlock.type === 'gallery' && currentBlock.gallery.length) {
        const gallery = this.listImg.filter(({ _id }) => currentBlock.gallery.includes(_id));
        const galleryIds = [];
        for (let i = 0; i < gallery.length; i++) {
          const { _id, ...image } = gallery[i];
          const id = await this.addImage(image);
          galleryIds.push(id);
        }
        fields.gallery = galleryIds;
      }

      if (currentBlock.type === 'test' && currentBlock.variants.length) {
        const variants = this.listVariants.filter(({ _id }) => currentBlock.variants.includes(_id));
        const variantsIds = [];
        for (let i = 0; i < variants.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          const variant = await this.addVariant({
            block: newBlock._id,
            difficulty: variants[i].difficulty,
            type: variants[i].practiceType,
          });
          variantsIds.push(variant._id);

          const { practice } = variants[i];

          if (practice.question) {
            const questionObj = {};
            if (variants[i].practiceType === 'Matching' || variants[i].practiceType === 'Choice') {
              questionObj.titleQuestion = practice.question;
            } else {
              questionObj.question = practice.question;
            }
            if (practice.sharedCode) {
              questionObj.sharedCode = practice.sharedCode;
            }
            // eslint-disable-next-line no-await-in-loop
            await this.setQuestion({
              variant: variant._id,
              questionObj,
            });
          }

          if (practice.answers && practice.answers.length) {
            if (variants[i].practiceType === 'CombinationLock') {
              const cols = (
                await this.setAnswer({
                  variant: variant._id,
                  answerObj: {
                    answers: practice.answers.map(({ x }) => ({ x, rows: [] })),
                  },
                })
              ).practice.answers;

              const rows = await this.setAnswer({
                variant: variant._id,
                answerObj: {
                  answers: cols.map(({ _id, x }) => ({
                    _id,
                    x,
                    rows: practice.answers[x].rows.map(({ y, value }) => ({
                      y,
                      value,
                    })),
                  })),
                },
              });
            } else {
              for (let j = 0; j < practice.answers.length; j++) {
                const { _id, ...answerObj } = practice.answers[j];
                // eslint-disable-next-line no-await-in-loop
                await this.setAnswer({
                  variant: variant._id,
                  answerObj,
                });
              }
            }
          }

          if (practice.questions && practice.questions.length) {
            for (let j = 0; j < practice.questions.length; j++) {
              const { _id, ...questionObj } = practice.questions[j];
              // eslint-disable-next-line no-await-in-loop
              await this.setQuestion({
                variant: variant._id,
                questionObj,
              });
            }
          }

          if (practice.image) {
            // eslint-disable-next-line no-await-in-loop
            await this.setAnswer({
              variant: variant._id,
              answerObj: { image: practice.image },
            });
          }

          if (practice.gallery1) {
            await this.copyGallery('gallery1', practice.gallery1, variant._id);
          }
          if (practice.gallery2) {
            await this.copyGallery('gallery2', practice.gallery2, variant._id);
          }
          if (practice.gallery3) {
            await this.copyGallery('gallery3', practice.gallery3, variant._id);
          }

          if (practice.video) {
            // eslint-disable-next-line no-await-in-loop
            await this.setQuestion({
              variant: variant._id,
              questionObj: {
                video: practice.video,
              },
            });
          }
        }
        fields.variants = variantsIds;
      }
      await this.changeBlock(fields);
      await this.pushNewBlock(newBlock, currentBlock.idx);
    },

    async copyGallery(name, gallery, variantId) {
      const ids = [];
      for (let i = 0; i < gallery.length; i++) {
        const { _id, ...image } = this.listImg.find(({ _id }) => _id === gallery[i]);
        // eslint-disable-next-line no-await-in-loop
        const id = await this.addImage(image);
        ids.push(id);
      }
      await this.setAnswer({
        variant: variantId,
        answerObj: { [[name]]: ids },
      });
    },
  },
  mounted() {
    this.activeTab = this.startTab;
  },
};
</script>

<style scoped>
.page-lesson__preview-blocks {
  scroll-snap-type: mandatory;
  -webkit-scroll-snap-type: mandatory;
}
</style>
