import { HTTP } from '@/http';

const lessons = {
  namespaced: true,
  state: {
    ids: [],
    newLesson: {
      isOpenModal: false,
      _id: '',
      name: null,
      path: null,
    },
  },
  mutations: {
    FETCH_LESSONS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    SET_NEW_LESSON_DATA: (state, payload) => {
      if (payload.name !== undefined) {
        state.newLesson.name = payload.name;
      }
      if (payload.path !== undefined) {
        state.newLesson.path = payload.path;
      }
      if (payload.isOpenModal !== undefined) {
        state.newLesson.isOpenModal = payload.isOpenModal;
      }
      if (payload._id !== undefined) {
        state.newLesson._id = payload._id;
      }
    },
  },
  getters: {
    newLessonData(state) {
      return state.newLesson;
    },
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.lessons[id]);
    },
  },
  actions: {
    async dublicateLesson(empty, params) {
      try {
        const { data } = await HTTP.post('/api/lesson-copy', params);
        return data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    fetchLessons({ commit }, params) {
      return HTTP.get('/crud/lesson', { params }).then((response) => {
        commit(
          'FETCH_LESSONS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'lessons',
            payload: response.data.models,
          },
          { root: true }
        );
        return response.data.models;
      });
    },
    changeLesson({ state, commit }, params) {
      return HTTP.post('/crud/lesson', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_LESSONS', [response.data._id]);
          commit(
            'FETCH_MODELS',
            {
              type: 'lessons',
              payload: [response.data],
            },
            { root: true }
          );

          return response.data;
        }
      });
    },
    getListLessons({ state, commit }, params) {
      return HTTP.get('/api/lessons', { params }).then((response) => response.data.lessons);
    },
    parseLesson({ state, commit }, params) {
      return HTTP.get('/api/lessonparse', { params }).then((response) => response.data.result);
    },
    downloadLessonArchive({ state, commit }, params) {
      return HTTP.get('/api/lessonzip', { params, responseType: 'blob' }).then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${params.lessonId}.zip`);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    copyLesson({ state, commit }, params) {
      return HTTP.post('/api/copylesson', { ...params }).then((response) => response.data.newLesson);
    },
  },
};

export default lessons;
