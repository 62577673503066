/**
 * Возвращает массив бем-нейминг классов
 * @param {string[]} names - Массив строк или строка для модификаторов. e.q ['default', 'dark'] or 'default'
 * @param {string} suffix - суффикс модификатора e.q theme
 * @param {string} baseClass - базовый класс e.q base-button
 * @return {Array} - e.q ['base-button--theme-default', 'base-button--theme-dark']
 */
function toBemModifer(names, baseClass, suffix = 'unset') {
  const arr = Array.isArray(names) ? names : names ? [names] : [];
  if (!arr.length) return [];
  return arr.map((el) => `${baseClass}--${suffix}-${el}`);
}

/**
 * Вернет объект из стора, если есть или заглушку
 * @param {object} state
 * @param {string} property
 * @param {string} _id
 * @return {object}
 */
function getOrFetch(state, property, _id) {
  if (state.DB[property][_id]) {
    return {
      _id,
      pending: false,
      ...state.DB[property][_id],
    };
  }
  return {
    _id,
    pending: true,
  };
}

function normalizeData(input) {
  const result = {};
  input.forEach((n) => (result[n._id] = n));
  return result;
}

function toggleFullscreen(event) {
  let element = document.body;

  if (event instanceof HTMLElement) {
    element = event;
  }

  const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

  element.requestFullScreen =
    element.requestFullScreen ||
    element.webkitRequestFullScreen ||
    element.mozRequestFullScreen ||
    function () {
      return false;
    };
  document.cancelFullScreen =
    document.cancelFullScreen ||
    document.webkitCancelFullScreen ||
    document.mozCancelFullScreen ||
    function () {
      return false;
    };

  isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
}
/**
 * Проигрывает кусочек видоса
 * @param {HTMLElement} video
 * @param {number[]} range
 * @param {number} interval
 */
function playVideoRange(video, [timeStart, timeEnd], interval = 100) {
  let timerId = video.getAttribute('data-timer-id');
  if (!timerId) {
    timerId = `video-timer-${Date.now()}`;
    video.setAttribute('data-timer-id', timerId);
  } else {
    clearTimeout(window[timerId]);
  }
  video.pause();
  video.currentTime = timeStart / 1000;

  function checkTime() {
    if (video.currentTime >= timeEnd / 1000) {
      video.pause();
      video.currentTime = timeEnd / 1000;
    } else {
      clearTimeout(window[timerId]);
      window[timerId] = setTimeout(checkTime, interval);
    }
  }

  const playPromise = video.play();

  // @see https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  if (playPromise !== 'undefined') {
    playPromise
      .then(() => {
        window[timerId] = setTimeout(checkTime, interval);
      })
      .catch((err) => {
        clearTimeout(window[timerId]);
      });
  } else {
    window[timerId] = setTimeout(checkTime, interval);
  }
  // Если вдруг рендж больше длины видео
  video.addEventListener('ended', () => {
    clearTimeout(window[timerId]);
  });
}

export { toBemModifer, getOrFetch, normalizeData, toggleFullscreen, playVideoRange };
