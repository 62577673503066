import { normalizeData } from '@/helpers/index';

export default {
  SET_LAYOUT(state, layout = 'BaseLayout') {
    state.layout = layout;
  },
  FETCH_MODELS(state, { type, payload }) {
    let data = {};
    if (type === 'users') {
      data = normalizeData(
        payload.map((n) => {
          n.fullName = `${n.firstName} ${n.lastName}`;
          return n;
        })
      );
    } else {
      data = normalizeData(payload);
    }

    state.DB[type] = { ...state.DB[type], ...data };
  },
  FETCH_VARIANT(state, payload) {
    const variant = state.DB.variants[payload._id];
    payload.practice = variant?.practice;
    const data = normalizeData([payload]);
    state.DB.variants = { ...state.DB.variants, ...data };
  },
};
