import Vue from 'vue';

import { default as Vuedals } from 'vuedals';
import VuePlyr from 'vue-plyr';

// Base Components
import BaseButton from '@/components/Base/BaseButton';
import BaseHeader from '@/components/Base/BaseHeader';
import BaseIcon from '@/components/Base/BaseIcon';
import BaseInput from '@/components/Base/BaseInput';
import BaseModal from '@/components/Base/BaseModal';
import BaseSpinner from '@/components/Base/BaseSpinner';
import BaseTag from '@/components/Base/BaseTag';
import BaseTextarea from '@/components/Base/BaseTextarea';
import BaseCollapsing from '@/components/Base/BaseCollapsing';
import BaseRadio from '@/components/Base/BaseRadio';
import BaseCheckbox from '@/components/Base/BaseCheckbox';
import BaseEditor from '@/components/Base/BaseEditor';
import BaseMathjaxText from '@/components/Base/BaseMathjaxText';
import BaseFile from '@/components/Base/BaseFile';
import { setLayout } from './routerMdw';
import store from './store/index';
import router from './router';
import App from './App';

const baseComponents = {
  BaseButton,
  BaseInput,
  BaseTextarea,
  BaseIcon,
  BaseTag,
  BaseHeader,
  BaseModal,
  BaseSpinner,
  BaseCollapsing,
  BaseRadio,
  BaseCheckbox,
  BaseEditor,
  BaseMathjaxText,
  BaseFile,
};

Vue.config.productionTip = false;

router.beforeEach(setLayout);

// registrate common components
for (const name in baseComponents) {
  Vue.component(name, baseComponents[name]);
}

Vue.use(Vuedals);
Vue.use(VuePlyr);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
