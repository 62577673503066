import { HTTP } from '@/http';

const apiURL = process.env.VUE_APP_FILE_API_URL;
const appSign = process.env.VUE_APP_FILE_API_SIGN;

function appendSign(fd) {
  fd.append('sign', appSign);
  return fd;
}

const fileServer = {
  namespaced: true,
  actions: {
    uploadSingle(context, payload) {
      if (payload instanceof FormData) {
        return new Promise((resolve, reject) => {
          HTTP.post(`${apiURL}/file/upload`, appendSign(payload))
            .then((response) => {
              if (response.data.file) {
                resolve(response.data.file.link);
                return;
              }
              reject(new Error('received no files'));
            })
            .catch((err) => {
              console.log('err %O', err);
              reject(err);
            });
        });
      }
      return Promise.reject(new TypeError('payload should be an valid FormData instance'));
    },
  },
};

export default fileServer;
